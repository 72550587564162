<template>
  <div class="container dynamic-page">
    <!-- 搜索 -->
    <div class="search content">
      <el-form :inline="true" :model="queryForm" class="demo-form-inline">
        <el-row>
          <el-col :span="14">
            <el-form-item>
              <el-select
                v-model="queryForm.termName"
                placeholder="学期"
                style="width: 188px"
              >
                <el-option
                  v-for="(item, index) in semesterlist"
                  :key="index"
                  :label="item.termName"
                  :value="item.termName"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="queryForm.deptId"
                placeholder="班级"
                style="width: 120px"
              >
                <el-option label="全部班级" value=""></el-option>
                <el-option v-for="item,index in classlist" :key="index" :label="item.deptName" :value="item.deptId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="queryForm.auditStatus"
                placeholder="状态"
                style="width: 95px"
              >
                <el-option label="全部状态" value=""></el-option>
                <el-option label="审核中" value="0"></el-option>
                <el-option label="已通过" value="1"></el-option>
                <el-option label="被驳回" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" style="text-align: right">
            <el-form-item>
              <el-input
                v-model="queryForm.userName"
                placeholder="输入学生姓名"
                style="width: 283px"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleQuery">搜索</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 动态 -->
    <div class="dynamic content" v-for="(item, index) in dynamic" :key="index">
      <div class="avatar">
        <el-avatar
          v-if="item.userInfo[0] && item.userInfo[0]!='null'"
          :src="imgSrc + item.userInfo[0]"
        ></el-avatar>
        <el-avatar
          v-else
          src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
        ></el-avatar>
        <div class="info">
          <h4>{{ item.userInfo[1] }}</h4>
          <p>{{ item.createTime.dateStr }} {{ item.createTime.time }}</p>
          <div class="status">
            <img
              v-if="item.auditStatus === '0'"
              src="../../../../assets/image/wei-shenhe.png"
              alt=""
            />
            <img
              v-if="item.auditStatus === '1'"
              src="../../../../assets/image/yi-tongguo.png"
              alt=""
            />
            <img
              v-if="item.auditStatus === '2'"
              src="../../../../assets/image/wei-tongguo.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="article">
        <div class="art-cont" v-html="item.content"></div>
        <div class="art-image">
		  <media-exhi v-if="item.imagesUrl" :file-data="item.imagesUrl"></media-exhi>
        </div>
        <div class="art-kind">
          <div class="kind kindC">
            {{item.indexOneName}} - {{ item.indexThreeName }}
          </div>
          <div class="operation">
            <el-button
              type="success"
              @click="handleAdopt(item, index)"
              v-if="item.auditStatus !== '1'"
			  size="mini"
              >通过</el-button
            >
            <el-button type="danger" @click="handleReject(item)" size="mini"
              v-if="item.auditStatus !== '2'"
              >驳回</el-button
            >
            <span class="padd"></span>
			<span
				v-if="item.questionNum > 5"
			  class="ques"
			  @click="clickQues(item)"
			  ><i class="el-icon-question"></i>{{ item.questionNum }}</span>
			<span class="padd" v-if="item.questionNum > 5">|</span>
            <span
              :class="{ dianzan: item.isDianZan !== '0' }"
              @click="handleDianzan(item, index)"
              ><i class="iconfont icon-dianzan"></i> {{ item.dianZanNum }}</span
            >
            <span class="padd">|</span>
            <span @click="openComment(item, index, {}, -1,  1)" ref="showInput"
              ><i class="iconfont icon-tubiaozhizuomoban"></i
            ></span>
          </div>
        </div>
        <!-- 评论内容 -->
        <div class="discuss" v-if="item.comments.length > 0">
          <ul>
            <li v-for="(el, elin) in item.comments" :key="elin">
				<div class="avatar">
				  <el-image
					v-if="el[1] && el[1]!='null'"
					:src="imgSrc + el[1]"
					fit="cover"
					style="width: 40px;height: 40px;"
				  ></el-image>
				  <el-image
					v-else
					src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
					fit="cover"
					style="width: 40px;height: 40px;"
				  ></el-image>
				</div>
              <div class="info">
                <h4>
                  <span>{{ el[2] }}</span
                  >：{{ el[4] }}
                </h4>
                <div style="display: flex; align-items: center">
                  <p>{{ el[3] ? el[3].dateStr : '' }} {{ el[3] ? el[3].time : '' }}</p>
                  <el-button size="mini" type="text" style="margin-left: 10px; color: #999999" @click="removeComment(item, index, el, elin, 1)">删除</el-button>
				  <el-button size="mini" type="text" style="margin-left: 10px; color: #999999"  ref="showInput" @click="openComment(item, index, el, elin, 2)">回复</el-button>
                </div>
				<!-- 2级评论 -->
				<div class="comments-2th" v-if="show2thCmtZone(el)">
					<ul>
					  <li v-for="(cmt, cmtIdx) in el[6].list" :key="cmtIdx">
						<div class="avatar">
							<el-image
							  v-if="cmt[1] && cmt[1]!='null'"
							  :src="imgSrc + cmt[1]"
							  fit="cover"
							  style="width: 40px;height: 40px;"
							></el-image>
							<el-image
							  v-else
							  src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
							  fit="cover"
							  style="width: 40px;height: 40px;"
							></el-image>
						</div>
					    <div class="info">
					      <h4>
					        <span>{{ cmt[2] }}</span
					        >：{{ cmt[4] }}
					      </h4>
					      <div style="display: flex; align-items: center">
					       <p>{{ cmt[3] ? cmt[3].dateStr : '' }} {{ cmt[3] ? cmt[3].time : '' }}</p>
					        <el-button  size="mini" type="text" style="margin-left: 10px; color: #999999" @click="removeComment(item, index, el, elin, 2, cmt, cmtIdx)">删除</el-button>
							<el-button size="mini" type="text" ref="showInput" style="margin-left: 10px; color: #999999" @click="openComment(item, index, el, elin, 3, cmt, cmtIdx)">回复</el-button>
					      </div>
					    </div>
					  </li>
					</ul>
					<span class="see-more-cmts" v-if="showLoadMore2ndCmt(el)" @click="getMore2ndComments(item, index, el, elin)"><i class="el-icon-plus"></i>查看更多回复</span>
				</div>
              </div>
            </li>
          </ul>
		  <span class="see-more-cmts" @click="getMore1stComments(item, index)" v-if="showLoadMoreCmt(item, index)"><i class="el-icon-plus"></i>查看更多评论</span>
        </div>
        <!-- 打开评论 -->
        <div class="comment">
          <div class="comment-cont" ref="showInput" v-if="item.isComments">
            <div class="comment-text">
              <el-form
                :model="form"
                :rules="rules"
                ref="commentsForm"
                class="demo-ruleForm"
              >
                <el-form-item prop="commentContent">
                  <el-input
                    type="textarea"
                    :rows="3"
                    v-model="commentContent"
					:placeholder="commentPh"
                    resize="none"
                    v-focus
					:id="`cometEditor${index}`"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="comment-btn">
              <el-popover placement="left-end" v-model="showEmoji">
				<template>
					<ul class="emoji-list">
						<li v-for="(item,idx) in emojiList" :key="idx" @click="inputEmoji(idx, index)">{{ item.char }}</li>
					</ul>
				</template>
				<span slot="reference" class="iconfont icon-xiaolian" style="font-size: 20px;"></span>
              </el-popover>
              <div>
				  <el-button type="primary" v-if="cmtMultiGrade.grade == 1" @click="submitComments(item, index)">评论</el-button>
				  <el-button type="text" v-if="cmtMultiGrade.grade != 1" @click="openComment(item, index, {}, -1,  1)">切换至评论</el-button>
				  <el-button type="success" v-if="cmtMultiGrade.grade != 1" @click="submitCmtReply(item.id)">回复</el-button>
              </div>
            </div>
          </div>
          <div class="comment-none" @click="openComment(item, index, {}, -1,  1)" v-else>
            评论
          </div>
        </div>
        <div class="cause" v-if="item.causeDes && item.auditStatus == 2">
			<span>驳回原因：{{ item.causeDes }}</span> / <span>驳回者：{{ item.updateBy }}</span>
		</div>
      </div>
    </div>

    <!-- 驳回原因 -->
    <el-dialog title="驳回个人写实记录" :visible.sync="open" width="500px" center :close-on-click-modal="false" :destroy-on-close="true">
      <el-form :model="form" :rules="rules" ref="form" class="demo-ruleForm">
        <el-form-item prop="causeDes" label="请选择驳回原因">
          <el-radio-group v-model="form.causeDes" class="reject-reason-gr">
            <el-radio label="内容虚假，不真实">内容虚假，不真实</el-radio>
            <el-radio label="文字内容或图片涉及违禁内容">文字内容或图片涉及违禁内容</el-radio>
            <el-radio label="与之前所发写实记录内容重复">与之前所发写实记录内容重复</el-radio>
            <el-radio label="内容敷衍，无意义">内容敷衍，无意义</el-radio>
            <el-radio label="其他">其他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="more" label="备注说明">
          <el-input
            v-model="form.more"
            type="textarea"
            autocomplete="off"
            :rows="3"
            placeholder="请输入备注说明，非必填"
          ></el-input>
        </el-form-item>
        <el-form-item label="扣分值">
          <el-select v-model="form.userCreditReduceScore" placeholder="请选择">
              <el-option label="5分" value="5"> </el-option>
              <el-option label="4分" value="4"> </el-option>
              <el-option label="3分" value="3"> </el-option>
              <el-option label="2分" value="2"> </el-option>
              <el-option label="1分" value="1"> </el-option>
            </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancle">关 闭</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 删除原因 -->
    <el-dialog title="删除评论" :visible.sync="dialog.delCmt" :destroy-on-close="true" :close-on-click-modal="false" width="500px" center>
      <el-form :model="rejectForm" ref="rejectForm" :rules="rules">
        <el-form-item label="删除原因" prop="delCommonsCause">
          <el-input v-model="rejectForm.delCommonsCause" type="textarea" :autosize="{ minRows: 4, maxRows: 6}" placeholder="请输入内容"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog('delCmt')">取 消</el-button>
        <el-button type="primary" @click="delPageCmtData">确 定</el-button>
      </div>
    </el-dialog>
	
	<el-dialog title="质疑详情" :visible.sync="dialog.quesDetail" width="500px" center>
		<el-form :model="quesDetail">
			<el-form-item prop="content" label="质疑内容：">
				<p v-for="(item, idx) in quesDetail.content">
					<span>{{ item.desc }}：{{ item.num }}</span>
				</p>
			</el-form-item>
			<el-form-item prop="reply" label="质疑回复：" v-if="!quesDetail.haveReply">
				<el-input type="textarea" placeholder="请输入质疑回复内容" v-model="quesDetail.reply"></el-input>
			</el-form-item>
			<el-form-item prop="reply" label="质疑回复：" v-else>
				{{ quesDetail.reply }}
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
		  <el-button @click="closeDialog('quesDetail')">取 消</el-button>
		  <el-button type="primary" @click="submitReply" :disabled="!quesDetail.reply" v-if="!quesDetail.haveReply">提交</el-button>
		</div>
	</el-dialog>
  </div>
</template>

<script>
import {
  getIndex,
  addRecord,
  getAllSemester,
  getTeacherClass,
  getPerRecord,
  updateStatus,
  getDianZan,
  getQuesCdNum,
  addQuesComets,
  getQuesComets,
} from "@/api/record/personal/personal";
import { getDic } from "@/api/public/search"
import mediaExhi from "@/components/mediaExhibition.vue"
import { dynamicFn } from "@/mixin/dynamic/index.js"
import { dynamicTeacherPersonal } from "@/mixin/dynamic/personal/teacher.js"
export default {
	components: {
		mediaExhi
	},
	mixins: [ dynamicFn, dynamicTeacherPersonal ],
  data() {
    return {
      imgSrc: this.imageSrc(),
      queryForm: {
        pageSize: "15",
        pageNum: "1",
        deptId: "",
        kind: "0",
        auditStatus: "",
        termName: "",
        userName: "",
      },
	  // showEmoji: false,
      pageTotal: 0,
      open: false,
      comment: "",
      cordId: '',
      semesterlist: [],
      classlist: [],
      dynamic: [],
      indexOne: [],
      indexTwo: [],
      indexThree: [],
      form: {
        indexRuleLevel: "0",
        file: [],
        more: '',
      },
	  quesDetail: {
		id: '',
		// 是否已回复
		haveReply: false,
		// 所有质疑数据汇总
		content: [],
		// 回复内容
		reply: ''
	  },
	  constData: {
		quesTp: []  
	  },
	  dialog: {
		quesDetail: false,
		delCmt: false
	  },
      rules: {
        causeDes: [
          { required: true, message: "请选择驳回原因", trigger: "change" },
        ],
        userCreditReduceScore: [{ required: true, message: '请选择扣分值', trigger: 'change' }],
        // commentContent: [
        //   { required: true, message: "请输入内容或表情", trigger: "blur" },
        // ],
        delCommonsCause: [{ required: true, message: '请输入删除评论原因', trigger: 'blur' }]
      },
      commentContent: '',
	  commentPh: '',
      rejectForm: {
		  delCommonsCause: ''
	  },
    };
  },
  created() {
    this.semester();
    this.getClass();
	this.getQuesDic()
  },
  mounted() {
    document.addEventListener("click", (e) => {
      if (this.$refs.showInput) {
        let isSelf;
        let showArr = this.$refs.showInput;
        for (let i = 0; i < showArr.length; i++) {
        	let v = showArr[i].$el ? showArr[i].$el : showArr[i]
          if (v.contains(e.target)) {
            isSelf = v.contains(e.target)
          }
        }
        if (!isSelf) {
          this.commentContent = ''
          this.dynamic.map((item) => {
            item.isComments = false;
          })
		  this.showEmoji = false
        }
      }
    });
    document.addEventListener("scroll",this.Scrollbottom)
  },
  methods: {
    getList() {
      getPerRecord(this.queryForm).then((res) => {
        this.pageTotal = res.data.pages
        this.dynamic = res.data.list;
        this.dynamic.map(async (item, idx) => {
          item.createTime = this.splitDate(item.createTime);
          item.isComments = item.isComments == "0" ? false : true;
          item.userInfo = item.userInfo.split(",")
          item.comments = await this.process1stCommts(item, idx, [], [])
        })
      });
    },
    getListCount() {
		getPerRecord(this.queryForm).then((res) => {
			let arr = res.data.list;
			arr.map(async (item, idx) => {
			  item.createTime = this.splitDate(item.createTime);
			  item.isComments = item.isComments == "0" ? false : true;
			  item.userInfo = item.userInfo.split(",");
			  item.comments = await this.process1stCommts(item, idx, [], [])
			  this.dynamic.push(item)
		  })
		})
	},
	// 质疑字典
	getQuesDic() {
		getDic({
			type: 'recordQuestion'
		}).then(res => {
			this.constData.quesTp = res.data
		})
	},
    handleQuery() {
      this.getList();
    },
    handleAdopt(item, idx) {
      updateStatus({
        id: item.id,
        auditStatus: "1",
        causeDes: this.form.causeDes + this.form.more,
      }).then((res) => {
        this.$message.success('已通过')
		this.dynamic[idx].auditStatus = '1'
      });
    },
    handleReject(item) {
      this.open = true
      this.cordId = item.id
    },
    handleSubmit() {
      this.$refs['form'].validate(valid => {
        if(valid) {
			let params = {
				id: this.cordId,
				auditStatus: "2",
				causeDes: this.form.causeDes + this.form.more,
				userCreditReduceScore: this.form.userCreditReduceScore
			}
			updateStatus(params).then((res) => {
				this.$message.success('驳回成功')
				this.dynamic.forEach((v, i) => {
					if (v.id == this.cordId) {
						this.dynamic[i].auditStatus = '2'
						this.dynamic[i].causeDes = params.causeDes
						this.dynamic[i].updateBy = this.$store.getters.name
					}
				})
				this.open = false
				this.resetForm('form')
			});
        }
      })
    },
	// 点赞
	handleDianzan(item, idx) {
	  getDianZan({ id: item.id }).then((res) => {
	    if (item.isDianZan == "0") {
	      item.isDianZan = "1"
	      item.dianZanNum++
	    } else {
	      item.isDianZan = "0"
	      item.dianZanNum--
	    }
	  })
	},
	// 获取质疑类型txt
	getQuesTpCn(cd) {
		let ret
		let list = this.constData.quesTp
		if (list.length > 0) {
			ret = list.filter(v => v.code == cd)
		}
		return ret[0].name
	},
	// 质疑
	async clickQues(item) {
		this.quesDetail.id = item.id
		this.quesDetail.reply = ''
		this.quesDetail.haveReply = false
		this.quesDetail.content = []
		let total = await getQuesCdNum({ id: item.id })
		let reply = await getQuesComets({ id: item.id })
		for(let i in total.data) {
			if (total.data[i] != 0) {
				this.quesDetail.content.push({
					desc: this.getQuesTpCn(i),
					num: total.data[i]
				})
			}
		}
		if (reply.data) {
			this.quesDetail.haveReply = true
			this.quesDetail.reply = reply.data
		}
		this.openDialog('quesDetail')
	},
	// 提交质疑回复
	submitReply() {
		addQuesComets({
			id: this.quesDetail.id,
			content: this.quesDetail.reply
		}).then(res => {
			this.$message.success('回复成功')
			this.closeDialog('quesDetail')
		})
	},
	// 打开弹窗
	openDialog(nm) {
		this.dialog[nm] = true
	},
	// 关闭弹窗
	closeDialog(nm) {
		this.dialog[nm] = false
	},
    is_self(id) {
      return id == this.$store.getters.userId
    },
    cancle() {
      this.open = false;
      this.resetForm("form");
      this.form = {
        content: undefined,
        indexOneId: undefined,
        indexTwoId: undefined,
        indexThreeId: undefined,
        indexThreeName: undefined,
        indexRuleLevel: 0,
        file: [],
        fileList: [],
        causeDes: undefined,
        more: '',
        userCreditReduceScore: undefined,
      };
    },
    // 获取学期
    semester() {
      getAllSemester({}).then((res) => {
        this.semesterlist = res.data;
        this.queryForm.termName = this.semesterlist[0].termName;
        this.getList()
      });
    },
    // 获取班级
    getClass() {
      getTeacherClass({}).then((res) => {
        // console.log(res);
        this.classlist = res.data;
      });
    },
    Scrollbottom () { 
      // let num = 0
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight - 300) {
        // console.log("滚动到底部了")
        if(this.queryForm.pageNum < this.pageTotal) {
          this.queryForm.pageNum ++
          this.getListCount()
        }
      }
      // console.log(num)
    },
  },
};
</script>
<style scoped>
.search .el-select >>> .el-input--suffix .el-input__inner {
  padding-left: 5px !important;
}
.el-textarea >>> .el-textarea__inner:focus {
  border: 1px solid #dcdfe6;
}
.el-form-item >>> .el-form-item__label{
  float: none;
}
</style>